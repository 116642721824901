import {Flex} from '@chakra-ui/react';

import {Image} from '@eksab/components';

interface WinnerCardProps {
  image: Media;
}

export const WinnerCard = ({image}: WinnerCardProps) => (
  <Flex
    className="group"
    pos="relative"
    rounded={['0.875rem', null, '1.625rem']}
    p={[2, 3, 5]}
    minW={['40%', '30%', '22%']}
    sx={{aspectRatio: '255 / 326'}}
    bg="linear-gradient(180deg, rgba(254, 225, 130, 0.15), rgba(90, 197, 123, 0.15)) white"
    _before={{
      content: '""',
      pos: 'absolute',
      inset: '-1px',
      zIndex: -1,
      rounded: ['calc(0.875rem + 1px)', null, 'calc(1.625rem + 1px)'],
      bgGradient: 'linear(to-r, yellow.400, green.400)',
    }}
  >
    <Image
      src={image?.original!}
      alt=""
      width={255}
      height={320}
      loading="lazy"
      w="full"
      h="auto"
      objectFit="cover"
      rounded={['0.5rem', '0.75rem', '1rem']}
      transition="transform 0.2s"
      _groupHover={{transform: 'scale(1.05)'}}
    />
  </Flex>
);
