import {Icon, IconProps} from '@chakra-ui/react';

export function BackIcon(props: IconProps) {
  return (
    <Icon width="15px" height="13px" viewBox="0 0 15 13" fill="none" strokeWidth="1.5" {...props}>
      <path
        d="M5.8414 11.3333L0.833293 6.47214M0.833293 6.47214L5.8414 1.61103M0.833293 6.47214L13.6666 6.47214"
        stroke="currentColor"
        strokeWidth="inherit"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
