import {useQuery} from '@tanstack/react-query';

import {api} from '@eksab/api';

interface Winner {
  id: number;
  image: Media;
}

const queryKey = ['winners'];

const queryFn = () => api.get('v2/winners').then((res) => res.data.data);

export function useWinnersQuery() {
  return useQuery<Winner[]>(queryKey, queryFn);
}

useWinnersQuery.queryKey = queryKey;
useWinnersQuery.queryFn = queryFn;
