import {Container, type ContainerProps, Heading} from '@chakra-ui/layout';

import {useTranslate} from '@eksab/i18n';

import {Image} from '@eksab/components/Image';

import {WinnerCard} from './WinnerCard';
import {useWinnersQuery} from './useWinnersQuery';
import trophyIcon from './trophy.png';

export function Winners(props: ContainerProps) {
  const t = useTranslate();
  const {data: winners} = useWinnersQuery();

  if (!winners) {
    return null;
  }

  return (
    <Container maxW="unset" px="0" {...props}>
      <Container flexDir="column" alignItems="center">
        <Image src={trophyIcon} alt="" width="120" w={[12, 16, 24, '7.5rem']} mb={[1, 2, 4]} />

        <Heading
          fontSize={['xl', '2xl', '2rem']}
          fontWeight="800"
          bg="linear-gradient(360deg, #408c57, #5ac57b)"
          bgClip="text"
          color="transparent"
          mb={[3.5, 6, 10, 14]}
        >
          {t('home.winners.heading')}
        </Heading>

        <Container variant="scroll" gap={[2, 4, 6, 8]} py="0.5">
          {winners.map((winner) => (
            <WinnerCard key={winner.id} image={winner.image} />
          ))}
        </Container>
      </Container>
    </Container>
  );
}
